import React from 'react'
import Man from "./assets/Man.svg"
import Logo from "./assets/Logo.svg"
import Card from "./assets/Card.svg"
import Vector from "./assets/Vector.svg"
import Vector1 from "./assets/Vector1.svg"
import { Link } from "react-router-dom";


function Landing() {

    return (
        <section
            class="bg-stone-50 flex flex-col h-screen items-center w-screen overflow-hidden relative">
            <navbar class="w-full mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a href="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                    <img alt="" src={Logo} />
                    <span class="font-fahkwang text-[22px] min-w-[199px] whitespace-nowrap text-black leading-none font-bold ml-3 text-xl">Expert Solutions</span>
                </a>

                {/* <nav class="md:ml-auto flex flex-wrap items-center text-base  justify-center">
                    <a href="#services" class="group mr-5 text-neutral-700 hover:text-gray-900 relative cursor-pointer">
                        Services
                        <span class="absolute transition-all duration-500 max-w-0 group-hover:max-w-[2rem] block h-px bg-gray-900 -bottom-1  left-0 right-1/2"></span>
                    </a>
                    <a class="group mr-5 text-neutral-700 hover:text-gray-900 relative cursor-pointer">
                        Let's Talk
                        <span class="absolute transition-all duration-500 max-w-0 group-hover:max-w-[2rem] block h-px bg-gray-900 -bottom-1  left-0 right-1/2"></span>
                    </a>

                    <a href="#contacts" class="group mr-5 text-neutral-700 hover:text-gray-900 relative cursor-pointer">
                        Contact Us
                        <span class="absolute transition-all duration-500 max-w-0 group-hover:max-w-[2rem] block h-px bg-gray-900 -bottom-1  left-0 right-1/2"></span>
                    </a>

                </nav> */}
            </navbar>
            <div
                class="mx-auto max-w-screen-xl px-4 py-28 md:py-40">

                <div class="mx-auto max-w-5xl text-center h-full">
                    <h1 className='font-fraunces lg:text-7xl md:text-5xl text-4xl  tracking-normal font-light' style={{ color: "#002EA1" }}>
                        Transform Your Life With The Guidance Of A Financial Guru
                    </h1>
                    <p className='mt-5 font-poppins text-lg text-zinc-600 text-opacity-100 leading-7 font-normal sm:text-xl/relaxed'>
                        Expert Solutions is a technology driven platform which provide various types of services such in India. We provide various types of services
                    </p>
                    {/* <AnimatePresence>
                        <AnimatedText
                            as='h1'
                            text='Transform Your Life With The Guidance Of A Financial Guru'
                            className="font-fraunces lg:text-7xl md:text-5xl text-4xl  tracking-normal font-light"
                            style={{ color: "#002EA1" }}
                            textVariants={{
                                hidden: { transition: { staggerChildren: 0.015 } },
                                visible: { transition: { staggerChildren: 0.015 } },
                            }}
                            letterVariants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: {
                                    opacity: 1,
                                    y: 0,
                                    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.5 },
                                },
                            }}
                        />
                        <AnimatedText
                            as='p'
                            className='mt-5 font-poppins text-lg text-zinc-600 text-opacity-100 leading-7 font-normal sm:text-xl/relaxed'
                            text="Expert Solutions is a technology driven platform which provide various types of services such in India. We provide various types of services"
                            textVariants={{
                                hidden: { transition: { staggerChildren: 0.015 } },
                                visible: { transition: { staggerChildren: 0.015 } },
                            }}
                            letterVariants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: {
                                    opacity: 1,
                                    y: 0,
                                    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.5 },
                                },
                            }}
                        />
                    </AnimatePresence> */}

                    <div class="flex flex-wrap justify-center h-full">
                        <img alt=""
                            // initial={{ x: "-300px", opacity: 0 }}
                            // animate={{ x: "0px", opacity: 1 }}
                            // transition={{
                            //     type: 'tween',
                            //     duration: 2
                            // }}
                            className='z-0 w-auto h-3/4'
                            src={Man}
                        />
                    </div>
                </div>
            </div>
            <img alt="" src={Card} className='hidden md:block absolute top-2/3 -translate-y-3/4 right-16' />
            <img alt="" src={Vector} className='hidden md:block absolute top-1/2 left-16' />
            <img alt="" src={Vector1} className='hidden md:block absolute top-2/3 left-44' />
            <Link to="/home">
                {/* <motion.div
                    whileHover={{
                        scale: [1, 0.75, 0.5, 0.25, 0, 0.25, 0.5, 0.75, 1],
                        transition: { duration: 1.2, ease: "linear" },
                        backgroundColor: '#9747FF',
                        color: '#fff',
                    }}
                    whileTap={{ scale: 0.9 }}
                    className="cursor-pointer rounded-full hover:border-0  border-2 border-black px-10 py-10 absolute bottom-12 md:bottom-24 right-4 md:right-20 font-fraunces whitespace-nowrap text-black text-center leading-none capitalize tracking-normal font-light"
                >
                    <span>Tap</span>
                </motion.div> */}
                <div
                    className="z-50 cursor-pointer rounded-full border-2 border-black px-8 py-10 absolute bottom-24 right-4 md:right-20 font-fraunces whitespace-nowrap text-gray-900 text-opacity-100 text-center leading-none capitalize tracking-normal font-light">
                    <span>Book</span>
                </div>
            </Link>
        </section>
    )
}

export default Landing