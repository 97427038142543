import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Landing from './landing';
import Home from './home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/landing",
    element: <Landing />
  },
  {
    path: "/home",
    element: <Home />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
