import React from 'react'
import Logo from "./assets/Logo.svg"
import Dot from "./assets/Dot.svg"
import Contact from "./assets/Contact.svg"
import ContactImg from "./assets/ContactImg.svg"
import Service1 from "./assets/Service1.svg"
import Service2 from "./assets/Service2.svg"
import Service3 from "./assets/Service3.svg"
import Service4 from "./assets/Service4.svg"
import Semicirlce from "./assets/Semicircle.svg"
import Play from "./assets/Play.svg"
import Arrow from "./assets/Arrow.svg"
import Hand from "./assets/Hand.svg"
import Star from "./assets/Star.svg"
import CurvyArrow from "./assets/CurvyArrow.svg"
import { useForm, ValidationError } from '@formspree/react';

function Home() {
    const [state, handleSubmit] = useForm("mknlwykb");
    if (state.succeeded) {
        alert("Your form has been submitted");
    }

    const firstWhatsAppClick = () => {
        const mobileNumber = '8698706254';
        const isMobile = window.matchMedia('(max-width: 768px)').matches;
        let url = '';

        if (isMobile) {
            url = `whatsapp://send?phone=${mobileNumber}`;
        } else {
            url = `https://web.whatsapp.com/send?phone=${mobileNumber}`;
        }
        window.open(url, '_blank');
    };

    const SecondWhatsAppClick = () => {
        const mobileNumber = '7558324906';
        const isMobile = window.matchMedia('(max-width: 768px)').matches;
        let url = '';

        if (isMobile) {
            url = `whatsapp://send?phone=${mobileNumber}`;
        } else {
            url = `https://web.whatsapp.com/send?phone=${mobileNumber}`;
        }
        window.open(url, '_blank');
    };

    return (
        <>

            <section
                class="bg-stone-50 flex flex-col h-screen items-center w-screen overflow-hidden relative">

                <navbar class="w-full mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <a href="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img alt="" src={Logo} />
                        <span class="font-fahkwang text-[22px] min-w-[199px] whitespace-nowrap text-black leading-none font-bold ml-3 text-xl">Expert Solutions</span>
                    </a>

                    <nav class="md:ml-auto flex flex-wrap items-center text-base  justify-center">

                        <a href="#services" class="group mr-5 text-neutral-700 hover:text-gray-900 relative cursor-pointer">
                            Services
                            <span class="absolute transition-all duration-500 max-w-0 group-hover:max-w-[2rem] block h-px bg-gray-900 -bottom-1  left-0 right-1/2"></span>
                        </a>
                        <button onClick={SecondWhatsAppClick} class="group mr-5 text-neutral-700 hover:text-gray-900 relative cursor-pointer">
                            Let's Talk
                            <span class="absolute transition-all duration-500 max-w-0 group-hover:max-w-[2rem] block h-px bg-gray-900 -bottom-1  left-0 right-1/2"></span>
                        </button>
                        <a href="#contacts" class="group mr-5 text-neutral-700 hover:text-gray-900 relative cursor-pointer">
                            Contact Us
                            <span class="absolute transition-all duration-500 max-w-0 group-hover:max-w-[2rem] block h-px bg-gray-900 -bottom-1  left-0 right-1/2"></span>
                        </a>
                    </nav>
                </navbar>
                <div
                    class="text-gray-600 body-font">
                    <div class="mx-auto flex lg:pl-5 lg:py-40 md:py-36 py-32 md:flex-row flex-col items-center">
                        <div
                            class="lg:flex-grow md:w-2/3  flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center overflow-auto">
                            <h1 className='font-fraunces lg:text-5xl xl:text-7xl md:text-4xl  text-3xl leading-tight text-black tracking-normal font-light'>
                                We Are India’s Largest Online Platform For Legal & Financial Services
                            </h1>
                            <p className='my-7 font-poppins text-lg text-zinc-600 leading-7 font-normal sm:text-xl/relaxed'>
                                Unleash the hidden potential of your finances with our top-rated chartered accountants: The secrets to wealth creation, and tax optimization!
                            </p>

                            <div className="flex">
                                <button
                                    onClick={firstWhatsAppClick}
                                    className="flex justify-center items-center border border-black rounded-full px-5 py-2.5 text-black text-lg font-medium mr-10">
                                    <img className='w-5 lg:mr-3 md:mr-2 mr-1' src={Play} alt="Play Icon" />
                                    Get In Touch
                                </button>
                                <a
                                    href="#services"
                                    className="flex justify-center items-center border border-black hover:text-black rounded-full px-5 py-2.5 text-black text-lg font-medium">
                                    <img className='w-4 mr-3' src={Arrow} alt="Arrow Icon" />
                                    Services
                                </a>
                            </div>

                        </div>
                        <div
                            class="flex justify-end lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                            <img className="hidden md:block" alt="hero" src={Dot} />
                        </div>
                    </div>
                </div >
                <img src={CurvyArrow} alt="" className=' hidden md:block absolute top-0 right-1/3' />
                <img src={Hand} alt="" className='animate-bounce hidden md:block absolute top-3/4 left-32' />
                <img src={Star} alt="" className='hidden md:block absolute top-3/4 right-1/4' />
            </section >
            <section
                id="services"
                class="bg-stone-50 flex flex-col h-auto items-center w-screen  relative">
                <img src={Semicirlce} alt="" className=' hidden md:block absolute top-0 right-1/4' />
                <div
                    class="container px-5 lg:py-24 md:py-20 py-12 mx-auto">
                    <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                        <h1 className='font-fraunces text-5xl text-neutral-800 leading-none tracking-normal font-normal'>
                            Our Services
                        </h1>
                    </div>
                    <div class="flex flex-wrap text-center justify-around">
                        <a class="cursor-pointer xl:w-1/4 md:w-1/2 p-4 xl:mr-20"
                            href="https://forms.gle/HnWYBEki7v5Wiew38" target="_blank" rel="noreferrer"
                        >
                            <div class="border border-gray-200 p-12 rounded-lg">
                                <div
                                    class="w-40 h-32 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                    <img
                                        alt=""
                                        src={Service1} />
                                </div>
                            </div>
                            <h2 className='my-5 font-fraunces text-3xl text-center whitespace-nowrap text-neutral-800  leading-7 font-light'>
                                Taxation
                            </h2>
                            <p className='font-poppins text-base text-center text-neutral-800  leading-6 font-normal'>Unleash the power of our expert CAs: seamless compliance, maximum savings, stress-free taxation!</p>
                        </a>
                        <a class="cursor-pointer xl:w-1/4 md:w-1/2 p-4 xl:mr-20"
                            href="https://forms.gle/G2W1eVqJ8ydAbxCaA" target="_blank" rel="noreferrer">
                            <div class="border border-gray-200 p-12 rounded-lg">
                                <div class="w-40 h-32 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                    <img
                                        alt=""
                                        src={Service2} />
                                </div>
                            </div>
                            <h2 className='my-5 font-fraunces text-3xl text-center whitespace-nowrap text-neutral-800  leading-7 font-light'>
                                Gov Registration
                            </h2>
                            <p className='font-poppins text-base text-center text-neutral-800  leading-6 font-normal'>Let our chartered accountants streamline your government registration process with ease.</p>
                        </a>
                        <a class="cursor-pointer xl:w-1/4 md:w-1/2 p-4"
                            href="https://forms.gle/soL8tH3gAg9knoGs9" target="_blank" rel="noreferrer">
                            <div class="border border-gray-200 p-12 rounded-lg">
                                <div class="w-40 h-32 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                    <img
                                        alt=""
                                        src={Service3} />
                                </div>
                            </div>
                            <h2 className='my-5 font-fraunces text-3xl text-center whitespace-nowrap text-neutral-800  leading-7 font-light'>Trademark</h2>
                            <p className='font-poppins text-base text-center text-neutral-800  leading-6 font-normal'>Trust our CA experts to ensure rock-solid protection for your valuable intellectual property.</p>
                        </a>
                        <a class="cursor-pointer xl:w-1/4 md:w-1/2 p-4"
                            href="https://forms.gle/H8TRmtMomt8zLkLdA" target="_blank" rel="noreferrer">
                            <div class="border border-gray-200 p-12 rounded-lg">
                                <div class="w-40 h-32 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                    <img
                                        alt=""
                                        src={Service4} />
                                </div>
                            </div>
                            <h2 className='my-5 font-fraunces text-3xl text-center whitespace-nowrap text-neutral-800  leading-7 font-light'>Business Services</h2>
                            <p className='font-poppins text-base text-center text-neutral-800  leading-6 font-normal'>Our CAs fine-tune your business with next-level strategies and game-changing services.</p>
                        </a>
                    </div>
                </div>
            </section >
            <section id="contacts"
                class="bg-stone-50 flex flex-col w-full h-full items-center lg:w-screen relative flex-wrap lg:h-auto lg:items-center lg:pt-20 md:pt-16 pt-8">

                <form
                    onSubmit={handleSubmit} class="py-8 mx-auto w-full ">
                    <div class="flex flex-wrap w-full justify-center text-center">
                        <img className='hidden md:block lg:mr-36' alt="" src={Contact} />
                        <h1 className="font-fraunces text-5xl text-neutral-800  leading-none tracking-normal font-normal">
                            Contact Us
                        </h1>

                        <img
                            className='hidden md:block md:ml-36' alt="" src={Contact} />
                    </div>
                    <div class="mx-auto flex lg:pl-5 py-8 md:flex-row flex-col items-center">
                        <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-12 md:mb-0 items-center text-center">
                            <h1 className='font-fraunces  text-xl md:text-3xl lg:max-w-xl w-5/6 leading-tight text-black tracking-normal font-semibold'>Get In Touch With Us On A One To One Call To Discuss Your Issues</h1>
                            <input
                                required
                                type="text"
                                id="name"
                                className="text-black font-fraunces mt-7 border-b bg-stone-50 focus:border-indigo-500 outline-none py-2 block lg:max-w-xl w-5/6 appearance-none leading-normal"
                                placeholder="Enter your name"
                            />
                            <ValidationError
                                prefix="Name"
                                field="name"
                                errors={state.errors}
                            />
                            <input
                                required
                                id="email"
                                type="email"
                                name="email"
                                className="text-black  font-fraunces mt-7 border-b bg-stone-50 focus:border-indigo-500 outline-none py-2 block lg:max-w-xl w-5/6 appearance-none leading-normal"
                                placeholder="Email-ID"
                            />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                            <input
                                id="message"
                                name="message"
                                className="text-black  font-fraunces mt-7 border-b bg-stone-50 focus:border-indigo-500 outline-none py-2 block lg:max-w-xl w-5/6 appearance-none"
                                placeholder="Add your comments"
                            />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />
                            <div className='lg:max-w-xl mt-7 flex lg:justify-end'>
                                <button
                                    type="submit"
                                    disabled={state.submitting}
                                    className="text-black border-black px-5 border lg:py-1 lg:px-4 focus:outline-none rounded-full text-lg"
                                >
                                    Submit
                                </button>
                            </div>
                            <p className='mt-14 lg:max-w-xl mb-10 w-5/6  text-black font-normal font-poppins text-base'>Shop no 229 & 230, 2nd Floor Mahavir Market Ostwal Empire, Boisar, Palghar, MH-401501. Contact no. 8698716343</p>

                        </div>
                        <div
                            className="border-black border-2 lg:mr-8 mt-8 rounded-md lg:p-12 flex justify-end lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                            <img class="" alt="hero" src={ContactImg} />
                        </div>
                    </div>
                </form>

                <footer class="bg-stone-50 w-full px-5 lg:mt-32 mt-12 mx-auto flex items-center sm:flex-row flex-col">
                    <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1" class="text-sm text-black sm:py-2 sm:mt-0 mt-4">worldsgenious1991@gmail.com
                    </a>
                    <p class="text-black inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">© 2023 Expert Solutions.
                        <a href="https://twitter.com/knyttneve" class="text-black ml-1" rel="noopener noreferrer" target="_blank">All rights reserved.</a>
                    </p>
                </footer>

            </section >
        </>
    )
}

export default Home
