import React from 'react'
import Logo from "./assets/Logo.svg"
import In from "./assets/In.svg"
import { Link } from "react-router-dom";

function Intro() {
  return (
    <section
      class="bg-red-300 flex flex-col h-screen items-center w-screen overflow-hidden relative">
      <navbar class="w-full mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a href="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <img src={Logo} alt="Logo" />
          <span class="font-fahkwang text-[22px] min-w-[199px] whitespace-nowrap text-black leading-none font-bold ml-3 text-xl">Expert Solutions</span>
        </a>
      </navbar>
      <div
        className='w-full h-full flex flex-col justify-evenly'>
        <img className='xl:w-1/5 lg:w-1/4 md:w-1/3 w-1/2 h-auto ml-auto' src={In} alt="" />
        <div className='pl-4'>
          <h1 className="font-halenoir-bold xl:text-9xl md:text-8xl text-5xl text-black text-opacity-100 font-bold">
            We are,
          </h1>
          <h1 className="font-halenoir-bold xl:text-9xl md:text-8xl text-5xl text-black text-opacity-100 font-bold">
            Expert Solutions.
          </h1>
          <p className='font-halenoir-bold text-2xl md:w-auto w-2/3 md:mt-4 mt-2 text-black text-opacity-100 leading-none font-bold'>
            Let’s help you with your registration
          </p>

        </div>
      </div>
      <Link to="/landing">
        <div
          className="cursor-pointer rounded-full  border-2 border-black px-10 py-10 fixed bottom-12 md:bottom-24 right-4 md:right-20 font-fraunces whitespace-nowrap text-black text-center leading-none capitalize tracking-normal font-light"
        >
          <span>Tap</span>
        </div>

      </Link>
    </section >
  )
}

export default Intro